<template>
  <div class="my-page-dashboard my-page" :class="{skeleton:!state.loaded}">
    <ul class="summary tight no-scrollbar">
      <li class="item" :class="r.class" v-for="(r, idx) in state.rects" :key="idx">
        <router-link :to="r.link" class="rect shadow" :style="{borderColor: r.color}">
          <span class="subject">{{ r.title }}</span>
          <div class="info">
            <span class="img" :style="{backgroundImage: `url(${r.ico})`}"></span>
            <span class="value">{{ r.value }}</span>
          </div>
        </router-link>
      </li>
    </ul>
    <div class="menu d-lg-none d-block" v-for="(s, idx) in menus" :key="`${component.name}Menus${idx}`">
      <b class="subject">{{ s.title }}</b>
      <ul class="tight">
        <li v-for="m in s.menu" :key="`${component.name}Item${m.name}`" v-show="m.valid && !['Dashboard', 'Logout'].includes(m.name)" @click="!m.click ? null : m.click()">
          <Anchor :href="m.paths[0]" class="no-underline">
            <div class="left">
              <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
              <span class="txt">{{ m.title }}</span>
            </div>
            <span class="img" style="background-image:url(/assets/ico/common.angle.right.svg)"></span>
          </Anchor>
        </li>
      </ul>
    </div>
    <div class="action d-lg-none d-block">
      <button class="btn font-sm" @click="()=> $store.dispatch('logoutAccount', {home: true})">
        <b>로그아웃</b>
      </button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mypageMenu from "@/scripts/mypageMenu";
import Anchor from "@/components/Anchor.vue";

function Component(initialize) {
  this.name = "pageMyPageDashboard";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    menu: Object
  },
  setup(props) {
    const component = new Component(() => {
      state.loaded = false;
      http.get("/api/member/dashboard").then(({data}) => {
        state.loaded = true;
        props.menu.desc = `${store.state.account.memberName}님은 ${data.body.investorGrade}입니다.`;
        state.isPassedCompatibilityTest = data.body.isPassedCompatibilityTest;
        state.rects.find(r => r.name === "investAmount").value = lib.getNumberFormat(data.body.investAmount) + "원";
        state.rects.find(r => r.name === "news").value = lib.getNumberFormat(data.body.newsCount) + "건";
        state.rects.find(r => r.name === "coupon").value = lib.getNumberFormat(data.body.couponCnt) + "개";
        state.rects.find(r => r.name === "point").value = lib.getNumberFormat(data.body.pointAmount) + "P";
        state.rects.find(r => r.name === "openProject").value = lib.getNumberFormat(data.body.openProjectCount) + "건";
        state.rects.find(r => r.name === "message").value = lib.getNumberFormat(data.body.messageCount) + "건";
        state.rects.find(r => r.name === "interest").value = lib.getNumberFormat(data.body.interestCount) + "건";
      });

      if (store.state.account.memberType === "C") {
        const newElement = {
          name: "archiveApplications",
          title: "나의 신청서",
          link: "/archive/applications",
          value: "나의 신청서 목록",
          ico: "/assets/ico/page.mypage.dashboard.menu.applications.svg",
        };
        state.rects.unshift(newElement);
      }
    });

    const state = reactive({
      loaded: false,
      isPassedCompatibilityTest: false,
      rects: [{
        name: "investAmount",
        title: "총 펀딩 금액",
        link: "/mypage/participant/reward",
        value: "0원",
        ico: "/assets/ico/page.mypage.dashboard.menu.total-amount.svg",
      }, {
        name: "point",
        title: "보유 포인트",
        link: "/mypage/point",
        value: "0000P",
        ico: "/assets/ico/page.mypage.dashboard.menu.point.svg",
      }, {
        name: "coupon",
        title: "사용 가능한 쿠폰",
        link: "/mypage/coupon",
        value: "0000개",
        ico: "/assets/ico/page.mypage.dashboard.menu.coupon.svg",
      }, {
        name: "news",
        title: "최근 소식",
        link: "/mypage/news",
        value: "0000건",
        ico: "/assets/ico/page.mypage.dashboard.menu.news.svg",
        click: null,
      }, {
        name: "interest",
        title: "관심 프로젝트",
        link: "/mypage/interest",
        value: "0000건",
        ico: "/assets/ico/page.mypage.dashboard.menu.interest.svg",
      }, {
        name: "openProject",
        title: "진행 프로젝트",
        link: "/mypage/open/reward",
        value: "0000건",
        ico: "/assets/ico/page.mypage.dashboard.menu.open.svg",
      }, {
        name: "message",
        title: "최근 메시지",
        link: "/mypage/message/receive",
        value: "0000건",
        ico: "/assets/ico/page.mypage.dashboard.menu.message.svg",
      }, {
        name: "payment",
        title: "바로가기",
        link: "/mypage/payment",
        value: "후원형 결제 수단",
        ico: "/assets/ico/page.mypage.dashboard.menu.payment.svg",
      }, {
        name: "investor-account-info",
        title: "바로가기",
        link: "/mypage/investor-account-info",
        value: "증권형 투자 계좌",
        ico: "/assets/ico/page.mypage.dashboard.menu.investor-account.svg",
      },]
    });

    const menus = mypageMenu.getMenus();

    return {component, state, menus};
  }
});
</script>

<style lang="scss" scoped>
.my-page-dashboard {
  .summary {
    display: flex;
    align-items: flex-start;
    gap: $px16;
    flex-wrap: wrap;

    > li {
      width: calc((100% - ($px16 * 2)) / 3);
      flex-shrink: 0;

      .rect {
        display: block;
        background: #fff;
        padding: $px20;
        text-decoration: none;
        border-radius: $px16;

        .subject {
          font-size: $px14;
        }

        .info {
          display: flex;
          gap: $px6;
          align-items: center;
          margin-top: $px16;

          > .img {
            width: $px20;
            height: $px20;
          }

          .value {
            font-weight: 600;
            font-size: $px16;
            line-height: 1;
          }
        }

        &.half {
          display: block;
          background: #fff;
          font-size: $px13;
          padding: $px15 $px25;
          height: $px50;
          border-color: $colorAnchor;
        }
      }
    }
  }

  .menu {
    margin-bottom: $px58;

    .subject {
      font-size: $px18;
      font-weight: 600;
      display: inline-block;
      margin-bottom: $px16;
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: $px24;
      margin-bottom: $px16;


      li {
        > a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: $px15;

          .img {
            width: $px24;
            height: $px24;
          }

          .left {
            display: flex;
            align-items: center;
            gap: $px12;
          }
        }
      }
    }
  }

  > .action {
    text-align: center;

    .btn {
      border: $px1 solid $colorBorder;
      border-radius: $px12;
      padding: $px10 $px20;
    }
  }

  &.skeleton {
    .summary > li .rect {
      .subject, .info > span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .summary {
      overflow: auto;
      flex-wrap: nowrap;
      padding: $px16;
      margin-bottom: $px28;
      margin-left: -15px;
      margin-right: -14px;

      > li {
        width: 30%;
      }
    }
  }

  @media(max-width: 767px) {
    .summary {
      > li {
        width: 60%;
      }
    }
  }
}
</style>